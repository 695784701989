import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container } from "../../components/Container"
import { UL } from "../../components/text/UL"
import { FlexContainer } from "../../components/FlexContainer"
import { FlexContent } from "../../components/text/FlexContent"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2White = styled.h2`
  color: #fff;
`

const StyledFlexContainer = styled(FlexContainer)`
  background-color: #9e24ae;
  color: #fff;
`

const actualLogoWidth = 252
const actualLogoHeight = 80
const actualLogoHMargin = 64
const actualLogoVMargin = 48

// Flexbox doesn't handle margin between flex items well.
// So turn the margin into padding of internal flex item
const logoHPadding = actualLogoHMargin / 2
const logoVPadding = actualLogoVMargin / 2
const logoWidth = actualLogoWidth + logoHPadding * 2
const logoHeight = actualLogoHeight + logoVPadding * 2

const ContainerLogo = styled(Container).attrs({
  // Remove outer padding created by
  // StyledFlexImg (padding of internal flex item)
  vPadding: -logoVPadding,
  hPadding: -logoHPadding,
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
`

const StyledImg = styled(Img)`
  width: ${({ theme }) => actualLogoWidth / theme.baseSize}rem;
  height: ${({ theme }) => actualLogoHeight / theme.baseSize}rem;
  vertical-align: middle;
`

const StyledFlexImg = styled.div`
  flex: 0 0 ${({ theme }) => logoWidth / theme.baseSize}rem;
  width: ${({ theme }) => logoWidth / theme.baseSize}rem;
  height: ${({ theme }) => logoHeight / theme.baseSize}rem;
  text-align: center;
  line-height: ${({ theme }) => logoHeight / theme.baseSize}rem;
`

const FlexImg = ({ fixed }) => (
  <StyledFlexImg>
    <StyledImg fixed={fixed} />
  </StyledFlexImg>
)

const Page = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.services_calibration
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <H1White>{page.title}</H1White>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Section>
        <StyledFlexContainer>
          <FlexContent minWidth={360}>
            <H2White>{page.electrical_dc.title}</H2White>
            <UL data={page.electrical_dc.desc} />
          </FlexContent>
          <FlexContent minWidth={360}>
            <H2White>{page.electrical_time.title}</H2White>
            <UL data={page.electrical_time.desc} />
          </FlexContent>
          <FlexContent minWidth={360}>
            <H2White>{page.microwave_rf.title}</H2White>
            <UL data={page.microwave_rf.desc} />
          </FlexContent>
        </StyledFlexContainer>
        <ContainerLogo>
          <FlexImg fixed={page.logo._1_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._1_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._1_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._1_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._2_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._2_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._2_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._2_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._3_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._3_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._3_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._3_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._4_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._4_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._4_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._4_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._5_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._5_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._5_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._5_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._6_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._6_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._6_3.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._6_4.childImageSharp.fixed} />

          <FlexImg fixed={page.logo._7_1.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._7_2.childImageSharp.fixed} />
          <FlexImg fixed={page.logo._7_3.childImageSharp.fixed} />
        </ContainerLogo>
      </Section>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            services_calibration {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              electrical_dc {
                title
                desc
              }
              electrical_time {
                title
                desc
              }
              microwave_rf {
                title
                desc
              }
              logo {
                _1_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _1_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _1_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _1_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _2_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _2_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _2_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _2_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _3_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _3_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _3_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _3_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _4_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _4_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _4_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _4_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _5_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _5_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _5_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _5_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _6_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _6_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _6_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _6_4 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _7_1 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _7_2 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
                _7_3 {
                  childImageSharp {
                    fixed(width: 252, traceSVG: { optTolerance: 0.01 }) {
                      ...GatsbyImageSharpFixed_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

import styled, { css } from "styled-components"

export const FlexContent = styled.div`
  flex: 1 1 ${({ theme, minWidth }) => minWidth / theme.baseSize}rem;
  padding: 1.5rem;
  ${({ theme, maxWidth }) =>
    typeof maxWidth !== "number"
      ? ""
      : css`
          max-width: ${maxWidth / theme.baseSize}rem;
        `};
`

import React from "react"

/**
 * Create ul tags from array to construct unordered list.
 */
export const UL = ({ data }) => {
  if (!Array.isArray(data)) {
    return null
  }
  return (
    <ul>
      {data.map((value, i) => (
        <li key={i}>{value}</li>
      ))}
    </ul>
  )
}
